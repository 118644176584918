body {
  font-size: 1rem;
  font-family: 'Playfair Display', serif;
  color: #00340A;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  font-family: 'Playfair Display SC', serif;
  font-weight: 700;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 1rem;
}

p {
  font-weight: 400;
  font-family: 'Playfair Display', serif;
  margin-block-start: 0;
  margin-block-end: 0;
  ;
}